import Highcharts from 'highcharts';

// To test out new plugins. This file is already registered with Nuxt (nuxt.config.js)
Highcharts.setOptions({
  plotOptions: {
    series: {},
  },
  chart: {
    style: {
      fontFamily: 'FranklinGothicURW',
    },
  },
  credits: {
    text: 'Source: UVA Darden Global Corporate Patent Dataset, 2019',
    href: 'https://patents.darden.virginia.edu',
    style: {
      fontSize: '9px',
      color: '#999999',
    },
  },
  // colors: ['#21313E', '#20575F', '#268073', '#53A976', '#98CF6F', '#EFEE69'],
  // colors: [
  //   '#8dd3c7',
  //   '#ffffb3',
  //   '#bebada',
  //   '#fb8072',
  //   '#80b1d3',
  //   '#fdb462',
  //   '#b3de69',
  //   '#fccde5',
  //   '#d9d9d9',
  //   '#bc80bd',
  //   '#ccebc5',
  //   '#ffed6f',
  // ],
  // colors: [
  //   '#a6cee3',
  //   '#1f78b4',
  //   '#b2df8a',
  //   '#33a02c',
  //   '#fb9a99',
  //   '#e31a1c',
  //   '#fdbf6f',
  //   '#ff7f00',
  //   '#cab2d6',
  //   '#6a3d9a',
  //   '#ffff99',
  //   '#b15928',
  // ],
  // colors: [
  //   '#f15a00',
  //   '#f39c12',
  //   '#f5e653',
  //   '#f000d0',
  //   '#aea8d3',
  //   '#913d88',
  //   '#0a539b',
  //   '#1e824c',
  //   '#c0392b',
  //   '#90c695',
  //   '#6c7a89',
  //   '#d2d7d3',
  // ],
  // colors: [
  //   '#d24693',
  //   '#59b74b',
  //   '#9b60c8',
  //   '#a8b446',
  //   '#7281cc',
  //   '#d99945',
  //   '#45b0cf',
  //   '#cc5643',
  //   '#59b78b',
  //   '#be688a',
  //   '#53813c',
  //   '#917538',
  // ],
  // colors: [
  //   '#cea0a8',
  //   '#75aec0',
  //   '#4c623b',
  //   '#432e54',
  //   '#7a3e36',
  //   '#87d2a0',
  //   '#7c7cc5',
  //   '#c8a44c',
  //   '#c851a1',
  //   '#d35242',
  //   '#90d14e',
  //   '#7f42c3',
  // ],
  colors: [
    '#e4cbb0',
    '#88aee1',
    '#cee8c2',
    '#d9bfdb',
    '#92c7b4',
    '#e9aeae',
    '#8cd1e5',
    '#b4bd9d',
    '#b3bddc',
    '#c5eceb',
    '#9db7b1',
    '#aacdd4',
  ],
});
