import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7f41076a = () => interopDefault(import('../pages/about-us/index.vue' /* webpackChunkName: "pages/about-us/index" */))
const _44e6576e = () => interopDefault(import('../pages/get-data/index.vue' /* webpackChunkName: "pages/get-data/index" */))
const _506f946b = () => interopDefault(import('../pages/interactive/index.vue' /* webpackChunkName: "pages/interactive/index" */))
const _4ae96a18 = () => interopDefault(import('../pages/publications/index.vue' /* webpackChunkName: "pages/publications/index" */))
const _1c829af1 = () => interopDefault(import('../pages/data-highlights/note-industry-classification.vue' /* webpackChunkName: "pages/data-highlights/note-industry-classification" */))
const _ffd59d54 = () => interopDefault(import('../pages/data-highlights/rise-of-asia/index.vue' /* webpackChunkName: "pages/data-highlights/rise-of-asia/index" */))
const _944f2042 = () => interopDefault(import('../pages/data-highlights/tech-leading-innovation/index.vue' /* webpackChunkName: "pages/data-highlights/tech-leading-innovation/index" */))
const _636b3542 = () => interopDefault(import('../pages/data-highlights/top-companies/index.vue' /* webpackChunkName: "pages/data-highlights/top-companies/index" */))
const _0547af50 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-us",
    component: _7f41076a,
    name: "about-us"
  }, {
    path: "/get-data",
    component: _44e6576e,
    name: "get-data"
  }, {
    path: "/interactive",
    component: _506f946b,
    name: "interactive"
  }, {
    path: "/publications",
    component: _4ae96a18,
    name: "publications"
  }, {
    path: "/data-highlights/note-industry-classification",
    component: _1c829af1,
    name: "data-highlights-note-industry-classification"
  }, {
    path: "/data-highlights/rise-of-asia",
    component: _ffd59d54,
    name: "data-highlights-rise-of-asia"
  }, {
    path: "/data-highlights/tech-leading-innovation",
    component: _944f2042,
    name: "data-highlights-tech-leading-innovation"
  }, {
    path: "/data-highlights/top-companies",
    component: _636b3542,
    name: "data-highlights-top-companies"
  }, {
    path: "/",
    component: _0547af50,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
